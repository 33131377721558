import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect(){
    var defaultDate = Date.today
    var flatpickr_share_date_config = {
      defaultDate: defaultDate,
      altFormat: "d/m/Y",
      altInput: true,
      locale: "fr"
    }

    $('#when:not(.flatpickr-input)').flatpickr(flatpickr_share_date_config);
  }
}
