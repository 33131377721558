import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  close(){
    $('.flash-messages-container').empty()
  }

  closeFlash(){
    $(event.target).remove()
  }

}
