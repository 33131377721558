$(document).on('turbolinks:load', function() {
  $('[data-channel-subscribe="notification"]').each(function(index, element) {
    var $element = $(element),
        user_id = $element.data('user-id')

    $element.animate({ scrollTop: $element.prop("scrollHeight")}, 1000)

    if (!App.notification){
      App.notification = App["notification_channel_" + user_id] = App.cable.subscriptions.create(
        {
          channel: "NotificationChannel",
          user_id: user_id
        },
        {

          received: function(data) {

            // Notifications on shares index
            if ($('[data-share-notif-id="'+ data.share_id +'"]').length){
              $('[data-share-notif-id="'+ data.share_id +'"]').replaceWith(data.notification)
            }
            else{
              $('.shares-container').prepend(data.notification)
            }


            // Notifications on Messages dropdowm menu
            if (!($('[data-channel-subscribe="share"]').length)){
              if (data.type == "new_message"){
                if ($('[data-share-notif-menu-id="'+ data.share_id +'"]').length){
                  $('[data-share-notif-menu-id="'+ data.share_id +'"]').replaceWith(data.menu_notification)
                }
                else{
                  $("#notifications-message").append(data.menu_notification)
                }
              }
              else{
                $("#notifications-message").append(data.notification)
              }
              $(".nomsg").remove()
              if (!$( ".gotmsg" ).length) {
                $("#dropdown-menu-btn").append(data.gotmsg)
              }
              if (!$('#ico-messages-mobile').hasClass( "ico-messages" )) {
                $("#ico-messages-mobile").toggleClass("ico-messages-grey ico-messages-grey-unread");
              }
            }
          },
        }
      );
    }
  });
});
